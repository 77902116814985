<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ getProjectTypeName(project.projectTypeId) }}</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <div class="section box-opt">
            <h2>Qual será a frequência de comprovação das metas?</h2>
            <v-layout class="mt-5">
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-5"
                large
                @click="setGoalFrequency(goalFrequency.Mensal)"
              >Mensal</v-btn>
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-5"
                large
                @click="setGoalFrequency(goalFrequency.Bimestral)"
              >Bimestral</v-btn>
              <v-btn
                flat
                round
                color="white"
                class="btn-default px-5"
                large
                @click="setGoalFrequency(goalFrequency.Trimestral)"
              >Trimestral</v-btn>
            </v-layout>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="text" />
    </v-layout>
  </div>
</template>

<script type="plain/text">
import Steps from "@/components/Steps.vue";
import HelpBox from "@/components/HelpBox.vue";
import { GoalFrequency } from "@/scripts/models/enums/goalFrequency.enum";
import { ProjectTypes, GetProjectTypeName } from "@/scripts/models/enums/projectTypes.enum";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import Goal from "@/scripts/models/goal.model";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import store from "@/store.js";
export default {
  components: {
    HelpBox,
    Steps
  },
  props: ["pageOptions"],
  data() {
    return {
      project: new Project(),
      projectService: new ProjectService(),
      text: HelpBoxText.ProjectStep50,
      getProjectTypeName: GetProjectTypeName,
      goalFrequency: GoalFrequency,
      projectValidationStatusIndex: 5,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { text: "Frequência" }
      ]
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId && to.query.projectId != 0) {
      let projectService = new ProjectService();
      projectService.findById(to.query.projectId).then(function(data) {
        let isValid = true;
        if (data.projectTypeId == ProjectTypes.Ticket) {
          store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Essa página não está disponível para esse tipo de projeto."
          );
          next(false);
        } else {
          if (!data.stepsValidationStatus.step3) {
            isValid = false;
            next({
              name: Routes.app.ProjectStep3,
              query: { projectId: data.id }
            });
          }
          if (isValid) {
            to.query.project = data;
            next();
          } else {
            store.commit(
              "SET_SNACKBAR_MESSAGE",
              "Não é possível entrar nessa página antes de preencher corretamente as anteriores."
            );
          }
        }
      });
    } else {
      next({ name: Routes.app.ProjectStep0 });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$route.query.saved) {
      this.$route.query.saved = true;
      this.projectService.save(this.project).then(next);
    } else {
      next();
    }
  },
  created() {
    this.project = this.$route.query.project;
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.pageOptions.changeClass(null);
  },
  methods: {
    diffDates() {
      var dateFrom = new Date(this.project.initialFinalDates[0]);
      var dateTo = new Date(this.project.initialFinalDates[1]);

      var months =
        (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
        (dateTo.getMonth() - dateFrom.getMonth());
      return months == 0 ? 1 : months;
    },
    splitValue(value, parts) {
      var result = [];
      let remain = value;
      let partsLeft = parts;
      for (var i = 0; partsLeft > 0; i++) {
        let current = Math.floor((remain + partsLeft - 1) / partsLeft);
        result[i] = current;
        remain -= current;
        partsLeft--;
      }
      return result;
    },
    setGoalFrequency(currentFrequency) {
      this.$set(this.project.stepsValidationStatus, "step5_0", true);
      //Adiciona Beneficiarios Diretos
      var numberOfFields = Math.ceil(
        this.diffDates() / Number(currentFrequency)
      );
      var newGoal = new Goal();
      newGoal.Order = 0;
      newGoal.name = "Beneficiários diretos";
      newGoal.projectId = this.project.id;
      newGoal.goalTypeId = 2; //Tipo: Quantidade
      newGoal.amount = this.project.directBeneficiaries;
      newGoal.goalVerificationId = 1;
      newGoal.goalsValuesList = [];
      newGoal.frequency = Number(currentFrequency);
      if (this.project.sameBeneficiaries) {
        for (var i = 0; i < numberOfFields; i++) {
          newGoal.goalsValuesList.push({
            Order: i,
            value: Number(this.project.directBeneficiaries)
          });
        }
      } else {
        let arrValues = this.splitValue(
          Number(this.project.directBeneficiaries),
          numberOfFields
        );
        for (var i = 0; i < arrValues.length; i++) {
          newGoal.goalsValuesList.push({
            Order: i,
            value: arrValues[i]
          });
        }
      }
      //Verifica se já existe uma meta para Beneficiarios Diretos
      if (this.project.goalList.length > 0) {
        newGoal.id = this.project.goalList[0].id;
      }
      this.projectService.saveGoal(
        function(data) {
          this.$router.push({
            name: Routes.app.ProjectStep51,
            query: { projectId: this.project.id }
          });
        }.bind(this),
        newGoal
      );
    }
  }
};
</script>